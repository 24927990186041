@import url("https://fonts.googleapis.com/css2?family=Bebas+Neue&family=Gilda+Display&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Inter&family=Poppins&display=swap');

.navbar-div{
    height: 120px;
position: absolute;
    background-color: transparent;
    padding-right: 0px; 
    padding-left: 0px;
    width: 98%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.navbar-img{
    display: flex;
    align-items: center;
    justify-content: center;
}

.navbar-brand {
    margin-right: 0px !important;
}

.navbar-logo110{
    width: 150px;
}

.navbar-div2{
   display: flex;
   justify-content: end;
   /* padding-right: 50px; */
}

.navbar-div3{
   display: flex;
   justify-content: start;
   /* padding-left: 50px; */

}

.navbar-text1{
    color: #FFF !important;
font-family: Inter;
font-size: 18px;
font-style: normal;
font-weight: 400;
line-height: normal;
}

.navbar-text1:hover{
    color: #FEAB02 !important;
font-family: Inter;
font-size: 18px;
font-style: normal;
font-weight: 400;
line-height: normal;
}

.navbar-text1.active{
    color: #FEAB02 !important;
font-family: Inter;
font-size: 18px;
font-style: normal;
font-weight: 400;
line-height: normal;
}







.mobile-navbar-div1 {
    background-color: #272727 !important;
    min-height: 90px;
    width: 100%;
    position: absolute;
    z-index: 999;
}

@media screen and (max-width: 991px) {
    /* .navbar-div1 {
        display: none;
    } */

    .mobile-navbar-div1 {
        display: block;
        position: absolute;
        width: 100%;
        top: 0px;
        left: 0px;
        z-index: 9;
    }
}

.mobile-nav-toogler {
    border: none;
}

.mobile-nav-item .nav-link {
     color: #FFF !important;
 font-family: 'Poppins', sans-serif;
font-size: 18px;
font-style: normal;
font-weight: 400;
line-height: normal;
    margin-top: 15px;
    margin-left: 0px !important;
}

.mobile-nav-item .nav-link:hover {
     color: #FEAB02 !important;
 font-family: 'Poppins', sans-serif;
font-size: 18px;
font-style: normal;
font-weight: 400;
line-height: normal;
}

.mobile-navbar-div2 {
    /* background: #EAEFFF; */
    background-color: #272727;
    padding-top: 15px;
    padding-bottom: 15px;
    width: 250px;
    height: 100vh;
    position: absolute;
    left: 0px;
    top: 88px;
    transform: translateX(-110%);
    transition: all 0.5s linear;
    /* box-shadow: 1px 1px 10px rgb(131, 131, 131); */
}

.mobile-navbar-div2.show {
    /* background: #EAEFFF; */
    background-color: #272727;
    padding-top: 15px;
    padding-bottom: 15px;
    padding-left: 40px;
    width: 250px;
    height: 100vh;
    position: absolute;
    left: 0px;
    top: 90px;
    transition: none;
    transform: translateX(0%) !important;
    transition: all 0.5s linear;
}

.mobile-nav-item.active .nav-link {
    color: #FEAB02 !important;
}


.mobile-nav-logo-12{
    width: 150px;
}


