.footer-div1 {
  background: #151623;
  padding-bottom: 50px;
  padding-top: 30px;
}

.footer-logo110{
  width: 200px;
}

.footer-text1 {
  color: #c7c7c7;
  /* text-align: center; */
  font-family: Poppins;
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.5px;
  margin-top: 12px;
}

.footer-div2 {
  column-gap: 9px;
}

.footer-div3 {
}

@media screen and (max-width: 576px) {
  .footer-div2 {
    margin-top: 35px;
  }
}
